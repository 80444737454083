export default {
  methods: {
    replaceStoreInfos(store, user) {
      return (content) => {
        const email = store.support_email
          || store.reply_to_email
          || user.email;

        const cgv = this.getLegalUrlText(store.terms_url);
        const privacy = this.getLegalUrlText(store.privacy_url);

        content = content.replace(/\[email\]/gi, email);
        content = content.replace(/\[link_cgv\]/gi, cgv);
        content = content.replace(/\[link_privacy\]/gi, privacy);

        return content;
      };
    },
    getLegalUrlText(url) {
      return url ? `
        <a href="${url}" target="_blank" rel="noreferrer noopener">
          Cliquez ici
        </a>
        pour voir les informations complémentaires.
      ` : `
        Pas d'informations complémentaires.
      `;
    },
  },
};
