<script>
import countries from '@shared/assets/data/countries.json';
import utilsMixin from '@shared/mixins/utils';
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import APIStore from '@school/services/API/Store';
import APICustomer from '@school/services/API/Customer';

export default {
  mixins: [utilsMixin],
  inject: ['buyable', 'store', 'price'],
  components: { StripeElementsSplitted, BasePasswordInput },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    tempCustomer: {
      type: Object,
      default: () => (false),
    },
    paymentPlan: {
      type: Object,
      default: null,
    },
  },
  static: {
    countries,
  },
  data() {
    const price = this.paymentPlan ? this.paymentPlan.amount : this.price;

    return {
      stripeError: null,
      stripeIsCompleted: false,
      discountIsLoading: false,
      discountIsApplied: false,
      hasChosenPaypal: false,
      hasAddressOpen: false,
      isFree: price <= 0,
      customer: {
        discount_code: '',
        firstname: '',
        lastname: '',
        password: '',
        name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        postal_code: '',
        state: '',
        country_code: 'FR',
      },
    };
  },
  computed: {
    submitIsDisabled() {
      return (
        (!this.isFree && !this.stripeIsCompleted)
        || (this.customer.discount_code !== '' && !this.discountIsApplied)
      );
    },
    canUseCoupon() {
      return !this.isFree && (
        this.buyable.discount_price == null
        || this.paymentPlan
      );
    },
    canUsePayPal() {
      return (
        !this.isFree
        && !this.paymentPlan
        && this.store.can_accept_paypal
        && !this.store.feature_options.paypal.disabled
      );
    },
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        this.$emit('update', this.customer);
      },
    },
    paymentPlan(value) {
      if (value) {
        this.$nextTick(() => (this.hasChosenPaypal = false));
      }
    },
  },
  methods: {
    applyCoupon() {
      const payment_schedule_id = this.paymentPlan ? this.paymentPlan.id : undefined;
      const price = this.paymentPlan ? this.paymentPlan.amount : this.price;

      this.discountIsLoading = true;
      this.discountIsApplied = false;
      this.isFree = price < 0;
      this.$emit('new-price');

      APIStore.getPricingData({
        payment_schedule_id,
        // training_uuid: this.buyable.uuid,
        ...(this.buyable.buyable_type === 'TRAINING'
          ? { training_uuid: this.buyable.uuid }
          : {}),
        ...(this.buyable.buyable_type === 'BUNDLE'
          ? { bundle_uuid: this.buyable.uuid }
          : {}),
        discount_code: this.customer.discount_code,
      })
        .then((data) => {
          data = data.data;
          this.discountIsApplied = !!data.additional_data.applied_discount_code;

          if (this.discountIsApplied) {
            this.$emit('new-price', data.applicable_price);
            this.isFree = data.applicable_price <= 0;
          } else {
            this.customer.discount_code = '';
            this.$buefy.toast.open({
              type: 'is-danger',
              message: `
                Ce code promo n'est pas utilisable
              `,
            });
          }
        })
        .finally(() => (this.discountIsLoading = false));
    },
    payWithPayPal() {
      if (!this.theForm?.checkValidity() || !this.tempCustomer?.uuid) {
        this.$buefy.dialog.alert(`
          Il manque des informations
          dans le formulaire pour pouvoir payer.
        `);
        return;
      }

      if (this.customer.discount_code !== '' && !this.discountIsApplied) {
        this.$buefy.dialog.alert(`
          <p>
            Vous avez rentré un code promo,
            mais vous ne l'avez pas appliqué.
          </p>
          <p class="mt-2">
            Merci de cliquer sur "appliquer".
          </p>
        `);
        return;
      }

      const w = 520;
      const h = window.innerHeight * 0.7;
      const x = (window.screen.width - w) / 2;
      const y = 100;
      const loader = this.$buefy.loading.open();
      let orderId;

      if (this.popup) {
        this.popup.close();
        this.popup = null;
      }

      this.popup = window.open(
        `${window.location.protocol}//${window.location.hostname}/paypal_init.html`,
        '_blank',
        `popup=1,toolbar=no,menubar=no,width=${w},height=${h},left=${x},top=${y}`,
      );

      if (this.popup == null) {
        this.showPopupBlockedDialog('PayPal', `
          <p class="mt-2">
            Vous pouvez utiliser le paiement par carte bancaire
            si cela ne fonctionne pas.
          </p>
        `);
        loader.close();
        return;
      }

      APICustomer.getPayPalOrder({
        customer_uuid: this.tempCustomer.uuid,
        ...(this.buyable.buyable_type === 'TRAINING'
          ? { training_uuid: this.buyable.uuid }
          : {}),
        ...(this.buyable.buyable_type === 'BUNDLE'
          ? { bundle_uuid: this.buyable.uuid }
          : {}),
        discount_code: this.customer.discount_code || undefined,
      })
        .then(({ data: { id, links = [] } = {} }) => {
          orderId = id;
          const url = links.find((v) => v.rel === 'approve')?.href;
          const { popup } = this;

          if (!popup || popup.closed) {
            return;
          }

          popup.location.href = url;
          popup.focus();

          const onPayPalMessage = (event) => {
            if (
              (
                event.origin !== (window.origin || window.location.origin)
                && event.source !== window
              ) || event.data?.name !== 'teachizy-paypal-popup'
            ) {
              return;
            }

            if (!popup.closed) {
              this.$emit('paypal-order', orderId);
              popup.close();
            }

            window.removeEventListener('message', onPayPalMessage);
          };

          window.addEventListener('message', onPayPalMessage);
        })
        .finally(() => loader.close());
    },
  },
  mounted() {
    console.log(this.$el);
    this.theForm = this.$el.closest('form');
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-12 pb-0">
      <b-field grouped>
        <b-field v-if="!tempCustomer || !tempCustomer.firstname" class="w-full is-flex-shrink-1">
          <b-input v-model="customer.firstname" placeholder="Prénom" expanded required />
        </b-field>
        <b-field v-if="!tempCustomer || !tempCustomer.firstname" class="w-full is-flex-shrink-1">
          <b-input v-model="customer.lastname" placeholder="Nom" expanded />
        </b-field>
      </b-field>
    </div>
    <div v-if="!isFree && buyable.feature_options.billing_address.enabled" class="column is-12 pb-0">
      <h3 class="mb-2">
        Indiquez votre adresse de facturation
      </h3>
      <b-field>
        <b-input v-model.trim="customer.name" placeholder="Société / Nom (optionnel)" />
      </b-field>
      <b-field>
        <b-input v-model.trim="customer.address_line_1" placeholder="Adresse (numéro + voie)" required />
      </b-field>
      <b-field>
        <b-input v-model.trim="customer.address_line_2" placeholder="Complément d'adresse" />
      </b-field>
      <b-field grouped>
        <b-field class="w-full is-flex-shrink-1">
          <b-input v-model.trim="customer.city" placeholder="Ville" required />
        </b-field>
        <b-field class="w-full is-flex-shrink-1">
          <b-input v-model.trim="customer.postal_code" placeholder="Code postal" required />
        </b-field>
      </b-field>
      <b-field>
        <b-select v-model="customer.country_code" expanded required>
          <option v-for="(country, code) in $options.static.countries" :key="code" :value="code">
            {{ country }}
          </option>
        </b-select>
      </b-field>
      <b-field v-show="customer.country_code != 'FR'">
        <b-input v-model.trim="customer.state" placeholder="Région/État (optionnel)" />
      </b-field>
    </div>
    <div v-if="!tempCustomer || !tempCustomer.firstname" class="column is-12 pb-0">
      <b-field label="Définissez votre mot de passe">
        <BasePasswordInput v-model="customer.password" />
      </b-field>
    </div>
    <div v-if="canUseCoupon" class="column is-12 pb-0">
      <b-field>
        <b-input v-model.trim="customer.discount_code" placeholder="Code promo" expanded
          @keyup.native.enter.prevent="customer.discount_code != '' && applyCoupon()" />
        <p class="control">
          <b-button type="is-primary is-custom" :loading="discountIsLoading" @click="applyCoupon">
            Appliquer
          </b-button>
        </p>
      </b-field>
      <p v-if="discountIsApplied" class="help is-primary is-custom">
        Le code promo a été appliqué !
      </p>
    </div>
    <div v-if="canUsePayPal" class="column is-12 pb-0">
      <b-field>
        <b-radio-button class="w-50p" type="is-primary is-custom" v-model="hasChosenPaypal" :native-value="false">
          <b-icon class="mr-3" icon="credit-card" />
          Carte bancaire
        </b-radio-button>
        <b-radio-button class="w-50p" type="is-primary is-custom" v-model="hasChosenPaypal" :native-value="true">
          <b-icon class="mr-3" pack="fab" icon="paypal" />
          Paypal
        </b-radio-button>
      </b-field>
    </div>
    <div v-if="!isFree && !hasChosenPaypal" class="column is-12 pb-0">
      <b-field label="Renseignez votre carte bancaire" :type="{ 'is-danger': stripeError && stripeError.message }"
        :message="stripeError && stripeError.message">
        <StripeElementsSplitted ref="card" :stripe-account="store.stripe_token" @error="stripeError = $event"
          @complete="stripeIsCompleted = $event" />
      </b-field>
    </div>
    <div class="column is-12">
      <b-button v-if="!isFree && hasChosenPaypal" ref="paypal" type="is-black" class="mt-3 py-6"
        :style="{ backgroundColor: '#0070ba' }" icon-left="paypal" icon-pack="fab" :loading="isLoading" expanded
        @click="payWithPayPal">
        Accéder avec <strong>PayPal</strong>
      </b-button>
      <b-button v-else rel="general" native-type="submit" type="is-primary is-custom" class="py-6"
        :disabled="submitIsDisabled" :loading="isLoading" expanded>
        {{ buyable.buyable_type === 'TRAINING' ? 'Accéder à la formation' : 'Accéder au pack' }}
      </b-button>

      <p class="mt-5 is-size-8 has-text-centered has-text-primary">
        <b-icon icon="shield-alt" />
        Inscription sécurisée par SSL
      </p>
    </div>
  </div>
</template>
