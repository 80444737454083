<script>
import storeHelpersMixin from '@shared/mixins/storeHelpers';

export default {
  mixins: [storeHelpersMixin],
  inject: ['store', 'authCustomer'],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const accept_marketing = this.authCustomer ? this.authCustomer.accept_marketing : true;
    const customer = {
      email: this.authCustomer ? this.authCustomer.email : '',
      accept_marketing: this.store.accept_marketing_enabled ? accept_marketing : undefined,
    };

    this.$emit('update', customer);

    return { customer };
  },
  watch: {
    customer: {
      deep: true,
      handler() {
        this.$emit('update', this.customer);
      },
    },
  },
  computed: {
    legalContent() {
      return this.replaceStoreInfos(this.store, this.store.user);
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div v-if="!authCustomer" class="column is-12">
      <b-field>
        <b-input v-model="customer.email" type="email" placeholder="Entrez votre adresse email"
          pattern="^.{2,}@.{2,}\..{2,}$" required />
      </b-field>
    </div>
    <div class="column is-12">
      <b-button native-type="submit" type="is-primary is-custom" icon-right="caret-right" :loading="isLoading" expanded>
        Continuer
      </b-button>
    </div>
    <p class="column is-12 py-0 is-size-8">
      <template v-if="store.terms_text">
        {{ store.terms_text }}
        <BaseShowCmsPage class="tdecoration-underline" :replaceContent="legalContent" page="cgv-du-formateur">
          conditions générales
        </BaseShowCmsPage>
        &amp;
        <BaseShowCmsPage class="tdecoration-underline" :replaceContent="legalContent"
          page="confidentialite-du-formateur">
          politique de confidentialité
        </BaseShowCmsPage>
      </template>
      <template v-else>
        En continuant, j'accepte les
        <BaseShowCmsPage class="tdecoration-underline" :replaceContent="legalContent" page="cgv-du-formateur">
          conditions générales
        </BaseShowCmsPage>
        et la
        <BaseShowCmsPage class="tdecoration-underline" :replaceContent="legalContent"
          page="confidentialite-du-formateur">
          politique de confidentialité
        </BaseShowCmsPage>
      </template>
    </p>
    <p class="column is-12">
      <b-checkbox v-if="store.accept_marketing_enabled" type="is-primary is-custom" class="is-size-8"
        v-model="customer.accept_marketing">
        <template v-if="store.accept_marketing_text">
          {{ store.accept_marketing_text }}
        </template>
        <template v-else>
          Je veux être tenu.e au courant des mises à jour et offres
        </template>
      </b-checkbox>
    </p>
  </div>
</template>
